import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";

export function initApolloClient(accessToken) {
  const httpLink = createHttpLink({ uri: window.apiUrl });
  const authLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      authorization: accessToken
    }
  }));
  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
  });
}
