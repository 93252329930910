export function getColorForIndex(index) {
  // Returns a color based on index position.  This function allows for a standardized palette of colors to display multiple lines on a single graph.
  const lastDigit = index % 10;
  const themeColors = [
    "#80CBC480",
    "#BCD4DE",
    "#f9cf51",
    "#17a2b8",
    "#BCD4DE",
    "#80CBC480",
    "#EEEEEE",
    "#f78dbd",
    "#f9cf51",
    "#17a2b8"
  ];
  return themeColors[lastDigit];
}
