import React from "react";

export default function DetailView(props) {
  return (
    <>
      <div className="col-12 col-xl-11 ml-auto mr-auto">
        <div className="row">
          <div className="system-select-header-group col-12 mb-2">
            <h1 className="system-headers">{props.header}</h1>
          </div>
        </div>
        {props.children}
      </div>
    </>
  );
}
