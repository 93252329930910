import React, { useLayoutEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

export default function InfoPopover({
  popoverTitle,
  popoverText,
  popoverPlacement = "top"
}) {
  const ref = React.useRef(null);
  useLayoutEffect(() => {
    const current = ref.current;
    window.jQuery(current).popover();
    return () => window.jQuery(current).popover("dispose");
  }, []);

  return (
    <i
      className="ml-auto"
      data-toggle="popover"
      title={popoverTitle}
      data-placement={popoverPlacement}
      data-content={popoverText}
      data-trigger="hover"
      ref={ref}
    >
      &nbsp;
      <FontAwesomeIcon
        icon={faQuestionCircle}
        color="gray"
        className="info-circle-svg"
      />
    </i>
  );
}
