import React from "react";
import { Line } from "rc-progress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";

export default function({
  percentage,
  strokeColor,
  trailColor,
  nanValue,
  diff
}) {
  const p = (
    100 *
    (isNaN(percentage)
      ? nanValue
      : 100 * percentage < 0
      ? 0
      : 100 * percentage > 100
      ? 1
      : percentage)
  ).toFixed(0);

  const color = "var(--" + (diff > 0 ? "green" : "red") + ")";
  return (
    <>
      <Line
        percent={p}
        strokeWidth="2"
        strokeColor={strokeColor}
        strokeLinecap="butt"
        trailWidth="2"
        trailColor={trailColor}
        style={{
          boxShadow: "6px 3px 6px #00000029",
          width: "231px",
          height: "31px"
        }}
      />
      <span className="oee-percent-label">{p}%</span>
      <p>
        <span
          style={{
            color: color
          }}
        >
          <FontAwesomeIcon
            icon={diff > 0 ? faArrowUp : faArrowDown}
            size="sm"
            color={color}
            className="oee-diff-arrow"
          />
          {diff}%
        </span>
      </p>
    </>
  );
}
