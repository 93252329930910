import React from "react";
import { GET_ALARMS_BY_CUSTOMER } from "components/Queries";
import { useQuery } from "react-apollo-hooks";
import "styles/AlarmsDB.css";
import { DateTime } from "luxon";
import LoadingCell from "components/LoadingCell";
import ErrorCell from "components/ErrorCell";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faCheckCircle
} from "@fortawesome/free-solid-svg-icons";

export default function AlarmsDB(props) {
  const user = props.user;
  const ownerDomain = user.ownerDomain;

  const endDate = React.useMemo(() => DateTime.local(), []);
  const startDate = endDate.minus({ days: 7 });

  const { loading, error, data } = useQuery(GET_ALARMS_BY_CUSTOMER, {
    variables: {
      startDate,
      endDate,
      ownerDomain
    }
  });

  if (loading) return <LoadingCell />;
  if (error) return <ErrorCell error={error} user={user} />;

  const recentAlarms = data.getAlarmsByCustomer.sort(function(a, b) {
    return new Date(b.time) - new Date(a.time);
  });

  const alarmDates = recentAlarms.map(function(alarm) {
    var alarmDate = new DateTime.fromISO(alarm.time).toLocaleString(
      DateTime.DATETIME_SHORT
    );
    return alarmDate;
  });

  if (recentAlarms.length > 0) {
    return (
      <div className="col-12">
        <div className="row">
          <div className="alarm-data">
            {recentAlarms.slice(0, 10).map((alarm, index) => (
              <div key={index} className="alarm-data-row row">
                <div className="col-12 p-0">
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    color="orange"
                    className="exclamation-triangle-svg mr-2"
                  />
                  {JSON.parse(alarm.data).alarm_name}
                </div>
                <div className="col-12 p-0">
                  <span className="badge badge-pill badge-success mr-1">
                    {alarm.robotAddress}
                  </span>
                  {alarmDates[index]}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="col-12">
        <div className="row">
          <div className="alarm-data border-top mt-2 pt-2">
            <h6>
              <FontAwesomeIcon
                icon={faCheckCircle}
                color="rgba(128, 203, 196, 0.5)"
                className="check-circle-svg mr-2"
              />
              There have been no alarms within the last 7 days.
            </h6>
          </div>
        </div>
      </div>
    );
  }
}
