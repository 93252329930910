import React from "react";
import gql from "graphql-tag";
import LoadingCell from "components/LoadingCell";
import ErrorCell from "components/ErrorCell";
import { useQuery } from "react-apollo-hooks";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareFull } from "@fortawesome/free-solid-svg-icons";
import "styles/PartsDB.css";

const PartsDB = ({ detailRow = false, dbWidget = false }) => {
  const data = useQuery(
    gql`
      query($eventType: String!, $numberOfEvents: Int!) {
        nMostRecent(eventType: $eventType, numberOfEvents: $numberOfEvents) {
          data
          eventType
          time
        }
      }
    `,
    {
      pollInterval: 10000,
      variables: {
        eventType: "PART_EVENT",
        numberOfEvents: 1000
      }
    }
  );

  if (data == null || data.loading) {
    return <LoadingCell />;
  } else if (data.error) {
    console.log(data.error);
    return <ErrorCell />;
  }

  let totalParts = 0;
  let partsPassed = 0;
  let percentPassed = 0;

  for (const part of data.data.nMostRecent) {
    if (JSON.parse(part.data).part_pass === true) {
      partsPassed += 1;
    }
    totalParts += 1;
    percentPassed = ((partsPassed / totalParts) * 100).toFixed(0);
  }

  const donutParts = (
    <DonutParts>
      <CircularProgressbar
        value={percentPassed}
        text={`${percentPassed}%`}
        strokeWidth={20}
        styles={buildStyles({
          pathColor: "#80CBC4",
          strokeLinecap: "butt",
          textColor: "#EEEEEEF2",
          trailColor: "#525868",
          fontWeight: 700
        })}
      >
        <p className="donut-text">Passed Parts</p>
      </CircularProgressbar>
    </DonutParts>
  );

  function DonutParts(props) {
    return (
      <>
        <div className="parts-donut" style={{ width: 225 }}>
          {props.children}
        </div>
        <p style={{ display: "flex", justifyContent: "space-evenly" }}>
          <span>
            <FontAwesomeIcon icon={faSquareFull} size="sm" color="#80CBC4" />{" "}
            Passed
          </span>
          <span>
            <FontAwesomeIcon icon={faSquareFull} size="sm" color="#525868" />{" "}
            Rejected
          </span>
        </p>
      </>
    );
  }

  return <>{donutParts}</>;
};

export default PartsDB;
