import "styles/Dashboard.css";
import React from "react";
import AlarmsDB from "components/dashboard/AlarmsDB";
import PartsDB from "components/dashboard/PartsDB";
import StartStopTimeDB from "components/dashboard/StartStopTimeDB";
import OEE from "graphs/OEE";
import { NavLink } from "react-router-dom";
import Availability from "graphs/Availability";
import Performance from "graphs/Performance";
import Quality from "graphs/Quality";
import CellHeader from "components/CellHeader";
import LoadingCell from "components/LoadingCell";
import ErrorCell from "components/ErrorCell";
import { useQuery } from "react-apollo-hooks";
import { GET_ROBOTS_AND_SYSTEMS } from "components/Queries";
import { DateTime } from "luxon";

const Dashboard = props => {
  const user = props.user;
  const ownerDomain = user.ownerDomain;

  const end = React.useMemo(() => DateTime.local(), []);
  const start = end.minus({ days: 7 });

  const { loading, error, data } = useQuery(GET_ROBOTS_AND_SYSTEMS, {
    variables: { ownerDomain }
  });

  if (loading) return <LoadingCell message={"Loading systems"} />;
  if (error) return <ErrorCell error={error} user={user} />;

  const systems = data.getSystems;

  const robots = [];

  systems.map(function(system) {
    const systemRobots = system.robots;
    return systemRobots.map(function(systemRobot) {
      return robots.push(JSON.parse(systemRobot).robot);
    });
  });

  return (
    <div className="col-12 col-xl-11 ml-auto mr-auto">
      <div className="row">
        <div className="db-cell col-xs-12 col-sm-6 col-xl-3 mb-1">
          <CellHeader
            title="Overall Efficiency"
            subtitle="THIS WEEK"
            popoverTitle="Overall Equipment Efficiency (OEE)"
            popoverText="OEE takes into account all losses. An OEE score of 100% means you are manufacturing only good parts, as fast as possible, with no stop time. An OEE score of 85% is considered world class for discrete manufacturers. For many companies, it is a suitable long-term goal."
          />
          <OEE user={user} />
        </div>
        <div className="db-cell col-xs-12 col-sm-6 col-xl-3 mb-1">
          <CellHeader
            title="Availability"
            subtitle="TODAY"
            popoverTitle="Availability"
            popoverText="Availability takes into account unplanned and planned stops. An Availability score of 100% means the process is always running during planned production time."
          />
          <Availability user={user} />
        </div>
        <div className="db-cell col-xs-12 col-sm-6 col-xl-3 mb-1">
          <CellHeader
            title="Performance"
            subtitle="TODAY"
            popoverTitle="Performance"
            popoverText="Performance takes into account slow cycles and small stops. A Performance score of 100% means when the process is running it is running as fast as possible."
          />
          <Performance user={user} />
        </div>
        <div className="db-cell col-xs-12 col-sm-6 col-xl-3 mb-1">
          <CellHeader
            title="Quality"
            subtitle="TODAY"
            popoverTitle="Quality"
            popoverText="Quality is the ratio of good output divided by actual output. Quality takes into account defects. A Quality score of 100% means there are no defects (only good parts are being produced)."
          />
          <Quality user={user} />
        </div>
      </div>

      <div className="row">
        <div className="alarm-wrapper-db db-cell col-xs-12 col-md-6 mb-1">
          <CellHeader
            title="Recent Production Line Alarms"
            subtitle="THIS WEEK"
            popoverTitle="Alarms"
            popoverText="Alarms are specific to your process and indicate there is an issue to be resolved. Example of alarms are “out of material” or “process slow."
          />
          <NavLink to="/alarms">
            <AlarmsDB user={user} />
          </NavLink>
        </div>

        <div className="db-cell cycle-time-wrapper-db col-xs-12 col-md-6 mb-1">
          <CellHeader
            title="Production Line Passed Parts Rate"
            subtitle="LAST 1000"
            popoverTitle="Parts"
            popoverText="Passed parts, also known as ‘good parts’ or ‘first pass yield’ is the making of a part that is ‘right the first time’ without rejecting it as scrap or reworking it. Parts are used in the Quality metric."
          />
          <NavLink to="/parts" className="white-db-link">
            <PartsDB user={user} />
          </NavLink>
        </div>
      </div>

      <div className="row">
        <div className="db-cell cycle-time-wrapper-db col-12 mb-1">
          <CellHeader
            title="Production Line Start & Stop Events"
            buttonTo="/start-stop-events"
            buttonText="View Details"
            buttonData={{ start: start, end: end, robots: robots }}
            subtitle="THIS WEEK"
            popoverTitle="Live Start & Stop Events"
            popoverText="Actual start and stop times across robots within a production line."
          />
          <StartStopTimeDB
            user={user}
            systems={systems}
            robots={robots}
            start={start}
            end={end}
          />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
