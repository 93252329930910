import "../styles/Login.css";
import React, { useState, Component } from "react";
import loginRobot from "../img/robot-login-500.png";
import hod from "../img/hod-new-logo.png";
import {
  CognitoUserPool,
  AuthenticationDetails,
  CognitoUser
} from "amazon-cognito-identity-js";

var poolData = {
  UserPoolId: window.cognitoUserPoolId,
  ClientId: window.cognitoClientId
};
var userPool = new CognitoUserPool(poolData);

var cognitoUser, sessionUserAttributes, authenticationPromise;

export class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signInHandler: props.signInHandler
    };
  }

  render() {
    return (
      <div className="container mt-5">
        <div className="row mt-5 p-3 justify-content-md-center">
          <div className="d-none d-lg-block col-12 col-lg-6 col-xl-5 p-0 login-left-pane">
            <img
              className="float-right login-image"
              src={loginRobot}
              alt="House of Design login"
            />
          </div>
          <div className="login-form col-12 col-lg-6 col-xl-7 p-3 p-lg-5">
            <div className="form-holder login-form-holder">
              <img
                src={hod}
                alt="House of Design Logo"
                className="hod-new-logo"
              />
              <h3 className="login-header">Log in</h3>
              <LoginForm signInHandler={this.state.signInHandler} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function NewPasswordForm(props) {
  const [newPassword, setNewPassword] = useState(null);
  const [passwordEntered, setPasswordEntered] = useState(true);
  const [passwordTooShort, setPasswordTooShort] = useState(true);
  const isAuthenticating = props.isAuthenticating;
  const setIsAuthenticating = props.setIsAuthenticating;
  const handleNewPassword = props.handleNewPassword;

  function handleNewPasswordChange(event) {
    const pw = event.target.value.trim();
    if (pw.length > 0) {
      setPasswordEntered(true);
      if (pw.length < 8) {
        setPasswordTooShort(true);
      } else {
        setPasswordTooShort(false);
        setNewPassword(pw);
      }
    } else {
      setPasswordEntered(false);
    }
  }

  function submitNewPasswordForm(e) {
    e.preventDefault();
    setIsAuthenticating(true);
    handleNewPassword(newPassword);
  }

  return (
    <form
      onSubmit={e => {
        submitNewPasswordForm(e);
      }}
      className="form"
    >
      <input
        className="password-input"
        placeholder="New Password"
        type="password"
        name="newPassword"
        onChange={handleNewPasswordChange}
      />
      {!passwordEntered && (
        <p className="text-danger">You need to enter your password.</p>
      )}
      {passwordEntered && passwordTooShort && (
        <p className="text-danger">
          Your password must be at least 8 characters.
        </p>
      )}
      {!isAuthenticating && (
        <button
          className="btn btn-danger"
          type="submit"
          disabled={!passwordEntered || passwordTooShort}
        >
          {" "}
          Change Password
        </button>
      )}
      {isAuthenticating && (
        <button className="btn btn-danger" type="submit" disabled={true}>
          {" "}
          Submitting{" "}
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        </button>
      )}
    </form>
  );
}

function LoginForm(props) {
  const signInHandler = props.signInHandler;
  const [emailEntered, setEmailEntered] = useState(true);
  const [email, setEmail] = useState(null);
  const [passwordEntered, setPasswordEntered] = useState(true);
  const [password, setPassword] = useState(null);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [authenticationError, setAuthenticationError] = useState();
  const [newPasswordRequired, setNewPasswordRequired] = useState(false);

  function handleAuthenticationError(errorMessage) {
    setAuthenticationError(errorMessage);
    setIsAuthenticating(false);
  }

  function authenticateWithCognito(e) {
    e.preventDefault();
    setAuthenticationError(null);
    setIsAuthenticating(true);
    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password
    });
    const userData = {
      Username: email,
      Pool: userPool
    };
    cognitoUser = new CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function(result) {
        setIsAuthenticating(false);
        signInHandler(cognitoUser, result);
      },
      onFailure: function(err) {
        const errorMessage = err.message || JSON.stringify(err);
        handleAuthenticationError(errorMessage);
      },
      newPasswordRequired: function(userAttributes) {
        // User was signed up by an admin and must provide new
        // password and required attributes, if any, to complete
        // authentication.

        // the api doesn't accept this field back
        delete userAttributes.email_verified;

        sessionUserAttributes = userAttributes;
        authenticationPromise = this;
        handleAuthenticationError("You need to change your password.");
        setNewPasswordRequired(true);
      }
    });
  }

  function handleNewPassword(newPassword) {
    cognitoUser.completeNewPasswordChallenge(
      newPassword,
      sessionUserAttributes,
      authenticationPromise
    );
  }

  function handleEmailChange(event) {
    const email = event.target.value.trim();
    if (email.length > 0) {
      setEmailEntered(true);
      setEmail(email);
    } else {
      setEmailEntered(false);
    }
    setAuthenticationError(null);
  }

  function handlePasswordChange(event) {
    const pw = event.target.value.trim();
    if (pw.length > 0) {
      setPasswordEntered(true);
      setPassword(pw);
    } else {
      setPasswordEntered(false);
    }
    setAuthenticationError(null);
  }

  return (
    <>
      <FlashHolder authenticationError={authenticationError}></FlashHolder>
      {!newPasswordRequired ? (
        <form
          onSubmit={e => {
            authenticateWithCognito(e);
          }}
          className="form"
        >
          {}
          <div className="form-group">
            <label for="login-email">Email</label>
            <input
              className="form-control"
              placeholder="Email"
              name="emailAddress"
              id="login-email"
              onChange={handleEmailChange}
            />
            {!emailEntered && (
              <p className="text-danger">
                You need to enter your email address.
              </p>
            )}
          </div>
          <div className="form-group">
            <label for="login-password">Password</label>
            <input
              className="form-control"
              placeholder="Password"
              type="password"
              name="password"
              id="login-password"
              onChange={handlePasswordChange}
            />
            {!passwordEntered && (
              <p className="text-danger">You need to enter your password.</p>
            )}
          </div>
          {!isAuthenticating && (
            <button
              className="btn btn-danger"
              type="submit"
              disabled={!emailEntered || !passwordEntered}
            >
              {" "}
              Login
            </button>
          )}
          {isAuthenticating && (
            <button className="btn btn-danger" type="submit" disabled={true}>
              {" "}
              Authenticating{" "}
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </button>
          )}
        </form>
      ) : (
        <NewPasswordForm
          handleNewPassword={handleNewPassword}
          isAuthenticating={isAuthenticating}
          setIsAuthenticating={setIsAuthenticating}
        />
      )}
    </>
  );
}

function FlashHolder(props) {
  const authenticationError = props.authenticationError;
  if (authenticationError != null) {
    return (
      <div className="ml-2 mr-3">
        <div className="alert alert-danger" role="alert">
          {authenticationError}
        </div>
      </div>
    );
  }
  return null;
}

export function getCachedSession() {
  var cognitoUser = userPool.getCurrentUser();
  var cachedSession = null;
  if (cognitoUser != null) {
    cognitoUser.getSession(function(err, session) {
      if (err) {
        console.error(err.message || JSON.stringify(err));
        return;
      }
      cachedSession = session;
    });
  }
  return { cognitoUser, cachedSession };
}
