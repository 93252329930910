import React from "react";
import LoadingCell from "../components/LoadingCell";
import ErrorCell from "../components/ErrorCell";
import "../styles/Availability.css";
import gql from "graphql-tag";
import { useQuery } from "react-apollo-hooks";
import { DateTime } from "luxon";
import ProgressCell from "../components/ProgressCell";

// AVAILABILITY = (RUN TIME(prod time - unscheduled downtime)) / production time

export default function Availability(props) {
  const endDate = React.useMemo(() => DateTime.local(), []);
  const startDate = endDate.startOf("Day");
  const user = props.user;
  const ownerDomain = user.ownerDomain;

  const start = startDate.minus({ days: 1 });
  const end = startDate.minus({ minutes: 1 });

  const data = useQuery(
    gql`
      query($startDate: String!, $endDate: String!, $ownerDomain: String!) {
        getOeeMetrics(
          startDate: $startDate
          endDate: $endDate
          ownerDomain: $ownerDomain
        )
      }
    `,
    {
      variables: {
        startDate: startDate.toISO({ includeOffset: false }),
        endDate: endDate.toISO({ includeOffset: false }),
        ownerDomain: ownerDomain
      }
    }
  );

  const prevDay = useQuery(
    gql`
      query($startDate: String!, $endDate: String!, $ownerDomain: String!) {
        getOeeMetrics(
          startDate: $startDate
          endDate: $endDate
          ownerDomain: $ownerDomain
        )
      }
    `,
    {
      variables: {
        startDate: start.toISO({ includeOffset: false }),
        endDate: end.toISO({ includeOffset: false }),
        ownerDomain: ownerDomain
      }
    }
  );

  if (data == null || data.loading) {
    return <LoadingCell gridArea="availability" />;
  } else if (data.error) {
    console.error("Availability Data Error: " + data.error);
    return <ErrorCell />;
  }

  if (prevDay == null || prevDay.loading) {
    return <LoadingCell gridArea="availability" />;
  } else if (prevDay.error) {
    console.error("Availability PrevDay Error: " + prevDay.error);
    return <ErrorCell />;
  }

  const x = data.data.getOeeMetrics;
  const availabilityPercent = JSON.parse(x).Availability;

  const y = prevDay.data.getOeeMetrics;
  const availabilityPrevPercent = JSON.parse(y).Availability;

  const diff = ((availabilityPercent - availabilityPrevPercent) * 100).toFixed(
    2
  );

  return (
    <ProgressCell
      strokeColor="#EEEEEE80"
      trailColor="#EEEEEE4D"
      percentage={availabilityPercent}
      nanValue={0}
      diff={diff}
    />
  );
}
