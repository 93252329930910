import React from "react";
import "styles/Alarms.css";
import AlarmsTable from "components/alarms/AlarmsTable";
import DetailView from "components/DetailView";
import CellHeader from "components/CellHeader";

export default function Alarms(props) {
  const user = props.user;
  return (
    <DetailView header="Alarms">
      <div className="row">
        <div className="col-12 db-cell">
          <CellHeader title="Recent System Alarms" subtitle="LAST 14 DAYS" />
          <div className="row">
            <div className="col-12 model-widget-holder">
              <AlarmsTable user={user} />
            </div>
          </div>
        </div>
      </div>
    </DetailView>
  );
}
