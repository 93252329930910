import React, { Component } from "react";
import LoadingCell from "../components/LoadingCell";
import ErrorCell from "../components/ErrorCell";
import { useQuery } from "react-apollo-hooks";
import Plot from "react-plotly.js";
import { DateTime } from "luxon";
import "../styles/StopTimeDB.css";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt, faCalendar } from "@fortawesome/free-solid-svg-icons";
import { GET_START_AND_STOP_EVENTS_BY_DATE_RANGE } from "../components/Queries";
import { DownloadDataButton } from "../components/DownloadData";
import { getColorForIndex } from "components/Colors";

function UpdateFormDates(props) {
  const startDate = props.startDate;
  const endDate = props.endDate;
  const referringObject = props.this;
  referringObject.setState({ endDate: endDate });
  referringObject.setState({ startDate: startDate });
}

class DataSelectionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(this.props.start.toString()),
      endDate: new Date(this.props.end.toString())
    };
  }

  render() {
    const updateStopTimeQuery = this.props.updateStopTimeQuery;
    const startDate = this.state.startDate;
    const endDate = this.state.endDate;

    return (
      <>
        {startDate > endDate && (
          <div className="alert alert-danger text-center" role="alert">
            Start date must be before or equal to the end date. Please update
            your dates and try again.
          </div>
        )}
        <div className="form-row">
          <div className="form-group col-12 col-md-6 col-lg-5 col-xl-4">
            <label>
              {" "}
              <FontAwesomeIcon
                icon={faCalendar}
                color="lightGray"
                className="mr-2"
              />
              Start:
            </label>
            <DatePicker
              className="form-control"
              selected={startDate}
              onChange={date =>
                UpdateFormDates({
                  startDate: date,
                  endDate: this.state.endDate,
                  this: this
                })
              }
              dateFormat="MMMM d, yyyy"
              id="start-date-picker"
            />
          </div>
          <div className="form-group col-12 col-md-6 col-lg-5 col-xl-4">
            <label>
              {" "}
              <FontAwesomeIcon
                icon={faCalendar}
                color="lightGray"
                className="mr-2"
              />
              End:
            </label>

            <DatePicker
              className="form-control"
              selected={endDate}
              onChange={date =>
                UpdateFormDates({
                  endDate: date,
                  startDate: this.state.startDate,
                  this: this
                })
              }
              dateFormat="MMMM d, yyyy"
              id="end-date-picker"
            />
          </div>
          <div className="form-group col-12 col-lg-2">
            <label>&nbsp;</label>
            <button
              id="query-refresh-button"
              className="btn btn-success btn-block from-control"
              onClick={() => updateStopTimeQuery()}
            >
              <FontAwesomeIcon
                icon={faSyncAlt}
                color="lightGray"
                className="mr-2"
              />
              Refresh
            </button>
          </div>
        </div>
      </>
    );
  }
}

export default class StartStopTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      robots: this.props.robots,
      startDate: this.props.startDate,
      endDate: this.props.endDate,
      start: this.props.start,
      end: this.props.end
    };
    this.updateStopTimeQuery = this.updateStopTimeQuery.bind(this);
  }

  updateStopTimeQuery() {
    const startDateSelector = document.getElementById("start-date-picker");
    const startJS = new Date(startDateSelector.value);
    const start = DateTime.fromJSDate(startJS);
    const endDateSelector = document.getElementById("end-date-picker");
    const endJS = new Date(endDateSelector.value);
    const end = DateTime.fromJSDate(endJS).endOf("day");

    if (endJS - startJS >= 0) {
      this.setState({
        start: start,
        end: end
      });
    }
  }

  render() {
    const robots = this.props.robots;
    const startDate = this.state.startDate;
    const endDate = this.state.endDate;
    const start = this.state.start;
    const end = this.state.end;

    return (
      <>
        <DataSelectionForm
          startDate={startDate}
          endDate={endDate}
          end={end}
          start={start}
          robots={robots}
          updateStopTimeQuery={this.updateStopTimeQuery}
        />
        <hr />
        <StopTimeQuery
          startDate={startDate}
          endDate={endDate}
          end={end}
          start={start}
        />
      </>
    );
  }
}

function StopTimeQuery(props) {
  const start = props.start;
  const end = props.end;

  const { loading, error, data } = useQuery(
    GET_START_AND_STOP_EVENTS_BY_DATE_RANGE,
    {
      variables: {
        startDate: start.toISO({ includeOffset: false }),
        endDate: end.toISO({ includeOffset: false })
      }
    }
  );

  if (loading) {
    return <LoadingCell message={"Fetching robot data"} />;
  }
  if (error) {
    return <ErrorCell />;
  }

  const apiData = data.startAndStopEventsByDateRange;
  const plotlyData = convertDataFromApiToPlotly(apiData);

  return (
    <div className="row">
      <div className="col-12 mt-2">
        <Viewer plotlyData={plotlyData} dataForDownload={apiData} />
      </div>
    </div>
  );
}

class Viewer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      plotlyData: this.props.plotlyData,
      dataForDownload: this.props.dataForDownload,
      headerRow: "",
      fileName: ""
    };
    this.downloadDataButtonComponent = React.createRef();
    this.dataDownloadHandler = this.dataDownloadHandler.bind(this);
  }

  dataDownloadHandler(data, displayRange) {
    const { fileName, headerRow, formattedData } = convertDataFromPlotlyToCSV(
      data,
      displayRange
    );
    this.downloadDataButtonComponent.current.changeData(
      fileName,
      headerRow,
      formattedData
    );
  }

  render() {
    return (
      <>
        <StartStopPlot
          data={this.state.plotlyData}
          dataDownloadHandler={this.dataDownloadHandler}
        />
        <DownloadDataButton
          ref={this.downloadDataButtonComponent}
          formattedData={this.state.dataForDownload}
          fileName={this.state.fileName}
          headerRow={this.state.headerRow}
        />
      </>
    );
  }
}

class StartStopPlot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      layout: {
        autosize: true,
        xaxis: {
          color: "#EEEEEE",
          tickfont: {
            family: "Roboto",
            size: 0.5,
            color: "#EEEEEE"
          },
          hoverformat: "%_I:%M:%S %p\n%b %_d, %Y"
        },
        yaxis: {
          rangemode: "tozero",
          color: "#EEEEEE",
          range: [0, 1.1],
          tickmode: "array",
          tickvals: [0, 1],
          ticktext: ["Running", "Stopped"],
          ticklen: 17,
          tickfont: {
            family: "Roboto",
            size: 0.5,
            color: "#EEEEEE"
          }
        },
        margin: {
          t: 0,
          b: 20,
          r: 10,
          l: 82
        },
        paper_bgcolor: "#3B3E43",
        plot_bgcolor: "#3B3E43",
        annotations: [
          {
            xref: "paper",
            yref: "paper",
            x: 0.0,
            y: 1.05,
            xanchor: "left",
            yanchor: "bottom",
            font: {
              family: "Roboto",
              size: 15,
              color: "#EEEEEE"
            },
            showarrow: false
          }
        ],
        showLegend: true,
        legend: {
          font: {
            family: "Roboto",
            size: 12,
            color: "#EEEEEE"
          }
        }
      },
      frames: [],
      config: {
        displaylogo: false,
        modeBarButtonsToRemove: ["pan2d", "lasso2d"]
      },
      dataDownloadHandler: this.props.dataDownloadHandler
    };
    this.updateHandler = this.updateHandler.bind(this);
  }

  updateHandler(figure) {
    this.setState(figure);
    const displayRange = figure.layout.xaxis.range;
    this.state.dataDownloadHandler(figure.data, displayRange);
  }

  render() {
    return (
      <Plot
        data={this.state.data}
        layout={this.state.layout}
        frames={this.state.frames}
        config={this.state.config}
        useResizeHandler={true}
        onInitialized={figure => this.setState(figure)}
        onUpdate={figure => this.updateHandler(figure)}
        style={{ width: "100%" }}
      />
    );
  }
}

function convertDataFromApiToPlotly(apiData) {
  var robots = [];
  var robot = "";
  var robotTimes = {};
  // iterate through start and stop time data (an array of array of events), where the first item in array is the robot IP, in string format.  Put the arrays into a hash, where the robot IP is the key.
  apiData.forEach(function(startStopData, i) {
    if (startStopData[0] !== robot) {
      robot = startStopData[0];
      robots.push(robot);
      robotTimes[startStopData[0]] = [];
    }
    robotTimes[startStopData[0]].push([startStopData[1], startStopData[2]]);
  });

  var plotlyData = [];

  // Create an arrray of start and top time values for each robot.
  robots.map(function(robotName, index) {
    const eventTimes = robotTimes[robotName];
    var x = [];
    var y = [];
    eventTimes.map(function(eventTime) {
      const formattedTime = DateTime.fromSeconds(parseFloat(eventTime[0]))
        .toUTC()
        .toISO({ includeOffset: false });
      x.push(formattedTime);
      if (eventTime[1] === "Stop") {
        return y.push(1);
      } else {
        return y.push(0);
      }
    });
    const graphColor = getColorForIndex(index);

    return plotlyData.push({
      x: x,
      y: y,
      marker: {
        color: graphColor,
        width: 6
      },
      type: "scatter",
      line: {
        color: graphColor,
        shape: "hv",
        width: 4
      },
      fill: "tozeroy",
      text: robotName,
      name: robotName
    });
  });
  return plotlyData;
}

function convertDataFromPlotlyToCSV(plotlyData, displayRange) {
  var formattedData = [];
  var displayStart = DateTime.fromSQL(displayRange[0]);
  var displayEnd = DateTime.fromSQL(displayRange[1]);

  plotlyData.map(function(data) {
    // do not process data that has been hidden in the legend in Plotly
    if (data["visible"] !== "legendonly") {
      let robotName = data["name"];
      let timeValues = data["x"].map(plotlyTime =>
        DateTime.fromISO(plotlyTime)
      );
      let eventTypes = data["y"].map(eventType => {
        // convert zeroes and ones to "start" and "stop" events.
        if (eventType === 1) {
          return "Stop";
        } else {
          return "Start";
        }
      });
      return timeValues.map((time, index) => {
        // only use events from the displayed range in Plotly
        if (time >= displayStart && time <= displayEnd) {
          return formattedData.push([
            robotName,
            time.toLocaleString(DateTime.DATETIME_SHORT).replace(",", ""),
            eventTypes[index]
          ]);
        }
      });
    }
  });

  const localZoneName = DateTime.local().offsetNameShort;
  const fileName =
    "StartStopTime " +
    displayStart.toLocaleString() +
    "-" +
    displayEnd.toLocaleString() +
    ".csv";
  const headerRow = [
    "Robot Name, Time (" + localZoneName + "), Event Type (Start or Stop)\n"
  ];

  return { fileName, headerRow, formattedData };
}
