import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

export class DownloadDataButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: this.props.fileName,
      headerRow: this.props.headerRow,
      formattedData: this.props.formattedData
    };
  }

  changeData(fileName, headerRow, formattedData) {
    this.setState({
      fileName: fileName,
      headerRow: headerRow,
      formattedData: formattedData
    });
  }
  render() {
    const formattedData = this.state.formattedData;
    var csvData = [this.state.headerRow];
    for (var line of formattedData) {
      csvData.push(line.join() + "\n");
    }
    const downloadURL = window.URL.createObjectURL(
      new Blob(csvData, { type: "text/csv" })
    );
    return (
      <a
        href={downloadURL}
        download={this.state.fileName}
        className="btn btn-secondary float-right mt-3"
        onClick={this.handleClick}
      >
        <FontAwesomeIcon
          icon={faDownload}
          color="lightGray"
          className="download mr-2"
        />
        Download
      </a>
    );
  }
}
