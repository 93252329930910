import React from "react";
import "../styles/OEE.css";
import gql from "graphql-tag";
import { useQuery } from "react-apollo-hooks";
import { DateTime } from "luxon";
import ProgressCell from "../components/ProgressCell";
import LoadingCell from "../components/LoadingCell";
import ErrorCell from "../components/ErrorCell";

// OEE = (parts passed * cycle time) / prod time 16800;

export default function OEE(props) {
  const user = props.user;
  const ownerDomain = user.ownerDomain;
  const endDate = React.useMemo(() => DateTime.local(), []);
  const startDate = endDate.startOf("Day").minus({ days: 7 });
  const end = startDate.minus({ minutes: 1 });
  const start = end.startOf("Day").minus({ days: 7 });

  const data = useQuery(
    gql`
      query($startDate: String!, $endDate: String!, $ownerDomain: String!) {
        getOeeMetrics(
          startDate: $startDate
          endDate: $endDate
          ownerDomain: $ownerDomain
        )
      }
    `,
    {
      variables: {
        startDate: startDate.toISO({ includeOffset: false }),
        endDate: endDate.toISO({ includeOffset: false }),
        ownerDomain: ownerDomain
      }
    }
  );

  const prevWeek = useQuery(
    gql`
      query($startDate: String!, $endDate: String!, $ownerDomain: String!) {
        getOeeMetrics(
          startDate: $startDate
          endDate: $endDate
          ownerDomain: $ownerDomain
        )
      }
    `,
    {
      variables: {
        startDate: start.toISO({ includeOffset: false }),
        endDate: end.toISO({ includeOffset: false }),
        ownerDomain: ownerDomain
      }
    }
  );

  if (data == null || data.loading) {
    return <LoadingCell />;
  } else if (data.error) {
    return <ErrorCell />;
  }

  if (prevWeek == null || prevWeek.loading) {
    return <LoadingCell />;
  } else if (prevWeek.error) {
    return <ErrorCell />;
  }

  const x = data.data.getOeeMetrics;
  var oeePercent = JSON.parse(x).OEE;

  const y = prevWeek.data.getOeeMetrics;
  var oeePrevPercent = JSON.parse(y).OEE;

  const diff = ((oeePercent - oeePrevPercent) * 100).toFixed(2);

  return (
    <ProgressCell
      strokeColor="#80cbc4"
      trailColor="#80cbc480"
      nanValue={0}
      percentage={oeePercent}
      gridArea="oee"
      diff={diff}
    />
  );
}
