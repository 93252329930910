import React, { useState } from "react";
import LoadingCell from "components/LoadingCell";
import ErrorCell from "components/ErrorCell";
import { GET_START_AND_STOP_EVENTS_BY_DATE_RANGE } from "components/Queries";
import { useQuery } from "react-apollo-hooks";
import Plot from "react-plotly.js";
import { DateTime } from "luxon";
import "styles/StopTimeDB.css";
import { getColorForIndex } from "components/Colors";

export default function LiveStartStopTime(props) {
  const user = props.user;
  const start = props.start;
  const end = props.end;
  const [layout, setLayout] = useState({
    xaxis: {
      color: "#EEEEEE",
      tickfont: {
        family: "Roboto",
        size: 0.5,
        color: "#EEEEEE"
      },
      hoverformat: "%_I:%M:%S %p\n%b %_d, %Y"
    },
    yaxis: {
      rangemode: "tozero",
      color: "#EEEEEE",
      range: [0, 1],
      tickmode: "array",
      tickvals: [0, 1],
      ticktext: ["Running", "Stopped"],
      ticklen: 12,
      tickfont: {
        family: "Roboto",
        size: 0.5,
        color: "#EEEEEE"
      }
    },
    paper_bgcolor: "#3B3E43",
    plot_bgcolor: "#3B3E43",
    showLegend: true,
    legend: {
      font: {
        family: "Roboto",
        size: 12,
        color: "#EEEEEE"
      }
    }
  });

  const { loading, error, data } = useQuery(
    GET_START_AND_STOP_EVENTS_BY_DATE_RANGE,
    {
      pollInterval: 10000,
      variables: {
        startDate: start.toUTC().toISO({ includeOffset: false }),
        endDate: end.toUTC().toISO({ includeOffset: false })
      }
    }
  );

  if (loading) return <LoadingCell message={"Fetching robot data"} />;
  if (error) return <ErrorCell error={error} user={user} />;

  const startStopTimes = data.startAndStopEventsByDateRange;

  var robots = [];
  var robot = "";
  var robotTimes = {};
  // iterate through start and stop time data (an array of array of events), where the first item in array is the robot IP, in string format.  Put the arrays into a hash, where the robot IP is the key.
  startStopTimes.forEach(function(startStopData) {
    if (startStopData[0] !== robot) {
      robot = startStopData[0];
      robots.push(robot);
      robotTimes[startStopData[0]] = [];
    }
    robotTimes[startStopData[0]].push([startStopData[1], startStopData[2]]);
  });

  var systemData = [];

  // Create an arrray of start and stop time values for each robot.
  robots.map(function(robotIP, index) {
    const eventTimes = robotTimes[robotIP];
    var x = [];
    var y = [];
    eventTimes.map(function(eventTime) {
      const formattedTime = DateTime.fromSeconds(parseFloat(eventTime[0]))
        .toLocal()
        .toISO({ includeOffset: false });
      x.push(formattedTime);
      if (eventTime[1] === "Stop") {
        return y.push(1);
      } else {
        return y.push(0);
      }
    });

    const graphColor = getColorForIndex(index);

    //  Add each robot to the Plotly graph
    return systemData.push({
      x: x,
      y: y,
      marker: {
        color: graphColor,
        width: 6
      },
      type: "scatter",
      fill: "tozeroy",
      line: {
        color: graphColor,
        shape: "hv",
        width: 4
      },
      text: robot,
      name: robot
    });
  });
  return (
    <div className="col-12">
      <Plot
        data={systemData}
        layout={layout}
        useResizeHandler={true}
        onUpdate={figure => setLayout(figure.layout)}
        style={{ width: "100%" }}
        config={{
          displaylogo: false,
          modeBarButtonsToRemove: ["pan2d", "lasso2d"]
        }}
      />
    </div>
  );
}
