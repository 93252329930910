import React from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo-hooks";
import {
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import { DateTime } from "luxon";
import "react-circular-progressbar/dist/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareFull } from "@fortawesome/free-solid-svg-icons";
import LoadingCell from "../components/LoadingCell";
import ErrorCell from "../components/ErrorCell";
import "../styles/Parts.css";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

const PartsGraph = () => {
  const data = useQuery(
    gql`
      query($eventType: String!, $numberOfEvents: Int!) {
        nMostRecent(eventType: $eventType, numberOfEvents: $numberOfEvents) {
          data
          eventType
          time
        }
      }
    `,
    {
      pollInterval: 10000,
      variables: {
        eventType: "PART_EVENT",
        numberOfEvents: 1000
      }
    }
  );

  if (data == null || data.loading) {
    return <LoadingCell />;
  } else if (data.error) {
    console.error(data.error);
    return <ErrorCell />;
  }

  let totalParts = 0;
  let partsPassed = 0;
  let percentPassed = 0;
  let partsFailed = 0;
  let donutPercent = 0;

  const recentParts = data.data.nMostRecent.sort(function(a, b) {
    return new Date(b.time) - new Date(a.time);
  });

  for (const part of data.data.nMostRecent) {
    if (JSON.parse(part.data).part_pass === true) {
      partsPassed += 1;
    } else {
      partsFailed += 1;
    }
    totalParts += 1;
    percentPassed = Math.round((partsPassed / totalParts) * 100);
    donutPercent = ((partsPassed / totalParts) * 100).toFixed(0);
  }

  function PassFailCell({ status }) {
    if (status) {
      return <span className="text-success">PASS</span>;
    } else {
      return (
        <span className="text-danger">
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            color="orange"
            className="exclamation-triangle-svg mr-2"
          />
          FAIL
        </span>
      );
    }
  }

  const donutParts = (
    <DonutParts label="Parts">
      <CircularProgressbarWithChildren
        value={percentPassed}
        text={`${donutPercent}%`}
        strokeWidth={20}
        styles={buildStyles({
          pathColor: "#80CBC4",
          strokeLinecap: "butt",
          textColor: "#EEEEEEF2",
          trailColor: "#525868",
          fontWeight: 700
        })}
      >
        <p className="donut-text">Passed Parts</p>
      </CircularProgressbarWithChildren>
    </DonutParts>
  );

  function DonutParts(props) {
    return (
      <>
        <div className="row">
          <div className="col-md-4 col-sm-6 col-xl-3 col-xs-12">
            <div className="row">
              <div className="col-12 parts-widget-circle-row">
                {props.children}
              </div>
              <div className="col-12 parts-widget-percent-row mt-2">
                <p
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    fontWeight: 700
                  }}
                >
                  <span>
                    <FontAwesomeIcon
                      icon={faSquareFull}
                      size="sm"
                      color="#80CBC4"
                      className="mr-1"
                    />
                    PASSED
                  </span>
                  <span>
                    <FontAwesomeIcon
                      icon={faSquareFull}
                      size="sm"
                      color="#525868"
                      className="mr-1"
                    />
                    REJECTED
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-8 col-sm-6 col-xl-9 col-xs-12">
            <div className="all-parts mt-3 row">
              <div className="ml-auto col-xs-12 col-md-3 col-xl-2 parts mt-1 mr-auto">
                <p className="parts-info-title">Parts Passed</p>
                <p className="parts-info">{partsPassed}</p>
              </div>
              <div className="ml-auto col-xs-12 col-md-3 col-xl-2 parts mt-1 mr-auto">
                <p className="parts-info-title">Parts Failed</p>
                <p className="parts-info">{partsFailed}</p>
              </div>
              <div className="ml-auto col-xs-12 col-md-3 col-xl-2 parts mt-1 mr-auto">
                <p className="parts-info-title">Total Parts</p>
                <p className="parts-info">{totalParts}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="table-responsive mt-5 parts-table-holder">
          <h3>Recent Parts</h3>
          <table className="table table-dark table-striped">
            <thead>
              <tr>
                <th className="" scope="col">
                  Status
                </th>
                <th className="" scope="col">
                  Part #
                </th>
                <th className="" scope="col">
                  Date
                </th>
                <th className="" scope="col">
                  Time
                </th>
              </tr>
            </thead>
            <tbody>
              {recentParts.map((part, index) => (
                <tr key={index}>
                  <td>
                    <PassFailCell status={JSON.parse(part.data).part_pass} />
                  </td>
                  <td>{JSON.parse(part.data).part_number}</td>
                  <td className="">
                    {DateTime.fromISO(part.time).toFormat("t")}
                  </td>
                  <td className="">
                    {DateTime.fromISO(part.time).toFormat("MM/dd/yyyy")}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  }

  return <>{donutParts}</>;
};

export default PartsGraph;
