export function initUserInfo(session) {
  const sessionPayload = session["idToken"]["payload"];
  var user = {};
  user.id = sessionPayload["sub"];
  user.email = sessionPayload["email"];
  user.ownerDomain = "gowestrobotics.com";
  user.groups = sessionPayload["cognito:groups"];
  user.first_name = sessionPayload["first_name"];
  user.last_name = sessionPayload["last_name"];
  user.isAdmin = checkIfAdmin(user);
  return user;
}
function checkIfAdmin(user) {
  return user.groups != null && user.groups.includes("GoWestAdmins");
}
