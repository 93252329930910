import React from "react";
import DetailView from "components/DetailView";
import CellHeader from "components/CellHeader";
import StartStopTime from "graphs/StartStopTime";
import LoadingCell from "components/LoadingCell";
import ErrorCell from "components/ErrorCell";
import { GET_ROBOTS_AND_SYSTEMS } from "components/Queries";
import { useQuery } from "react-apollo-hooks";
import { DateTime } from "luxon";
import "styles/StopTimeDB.css";

function LoadDefaultSystems(props) {
  const end = DateTime.local();
  const start = end.minus({ days: 7 }).startOf("day");
  const user = props.user;
  const ownerDomain = user.ownerDomain;
  const { loading, error, data } = useQuery(GET_ROBOTS_AND_SYSTEMS, {
    variables: { ownerDomain }
  });

  if (loading) return <LoadingCell message={"Loading systems"} />;
  if (error) return <ErrorCell error={error} user={user} />;

  const systems = data.getSystems;
  const robots = [];
  systems.map(function(system) {
    return system.robots.map(function(robot) {
      return robots.push(JSON.parse(robot)["robot"]);
    });
  });

  return (
    <StartStopTime
      ownerDomain={ownerDomain}
      robots={robots}
      end={end}
      start={start}
    />
  );
}

export default function StopTime(props) {
  const user = props.user;
  return (
    <DetailView header={"Start & Stop Time"}>
      <div className="row">
        <div className="col-12 db-cell">
          <CellHeader />
          <div className="col-12 model-widget-holder">
            <LoadDefaultSystems user={user} />
          </div>
        </div>
      </div>
    </DetailView>
  );
}
