import React from "react";
import gql from "graphql-tag";
import { useQuery } from "react-apollo-hooks";
import LoadingCell from "../components/LoadingCell";
import ErrorCell from "../components/ErrorCell";
import { DateTime } from "luxon";
import ProgressCell from "../components/ProgressCell";

// PERFORMANCE = (IDEAL CYCLE TIME * TOTAL COUNT) / RUN TIME(prod time - dt)

const Performance = props => {
  const endDate = React.useMemo(() => DateTime.local(), []);
  const startDate = endDate.startOf("Day");
  const user = props.user;
  const ownerDomain = user.ownerDomain;

  const start = startDate.minus({ days: 1 });
  const end = startDate.minus({ minutes: 1 });

  const data = useQuery(
    gql`
      query($startDate: String!, $endDate: String!, $ownerDomain: String!) {
        getOeeMetrics(
          startDate: $startDate
          endDate: $endDate
          ownerDomain: $ownerDomain
        )
      }
    `,
    {
      variables: {
        startDate: startDate.toISO({ includeOffset: false }),
        endDate: endDate.toISO({ includeOffset: false }),
        ownerDomain: ownerDomain
      }
    }
  );

  const prevDay = useQuery(
    gql`
      query($startDate: String!, $endDate: String!, $ownerDomain: String!) {
        getOeeMetrics(
          startDate: $startDate
          endDate: $endDate
          ownerDomain: $ownerDomain
        )
      }
    `,
    {
      variables: {
        startDate: start.toISO({ includeOffset: false }),
        endDate: end.toISO({ includeOffset: false }),
        ownerDomain: ownerDomain
      }
    }
  );

  if (data == null || data.loading) {
    return <LoadingCell />;
  } else if (data.error) {
    // return <LoadingCell gridArea="performance" />;
    console.error("PERFORMANCEPerformance DataError: " + data.error);
    return <ErrorCell />;
  }

  if (prevDay == null || prevDay.loading) {
    return <LoadingCell />;
  } else if (prevDay.error) {
    console.error("Performance PrevDay Error: " + prevDay.error);
    return <ErrorCell />;
  }

  const x = data.data.getOeeMetrics;
  const performancePercent = JSON.parse(x).Performance;

  const y = prevDay.data.getOeeMetrics;
  const performancePrevPercent = JSON.parse(y).Performance;

  const diff = ((performancePercent - performancePrevPercent) * 100).toFixed(2);

  return (
    <ProgressCell
      strokeColor="#8D99AE"
      trailColor="#8D99AE80"
      percentage={performancePercent}
      nanValue={0}
      gridArea="performance"
      diff={diff}
    />
  );
};

export default Performance;
