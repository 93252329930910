import React, { useState, createContext } from "react";

export const Context = createContext({});

export const Provider = props => {
  const { children } = props;

  const [showAddSystemModal, setShowAddSystemModal] = useState(false);

  const addSystemContext = {
    showAddSystemModal,
    setShowAddSystemModal
  };

  return (
    <Context.Provider value={addSystemContext}>{children}</Context.Provider>
  );
};

export const { Consumer } = Context;
