import gql from "graphql-tag";

export const GET_SYSTEM_BY_NAME = gql`
  query SystemByName($ownerDomain: String!, $systemName: String!) {
    getSystemByName(ownerDomain: $ownerDomain, systemName: $systemName) {
      systemName
      createdTime
      ownerDomain
      systemKey
      robots
    }
  }
`;

export const GET_ROBOTS_AND_SYSTEMS = gql`
  query RobotsAndSystems($ownerDomain: String!) {
    getSystems(ownerDomain: $ownerDomain) {
      systemName
      createdTime
      ownerDomain
      systemKey
      robots
    }
  }
`;

export const GET_EVENTS_BY_SYSTEM_NAME = gql`
  query EventsBySystemName(
    $ownerDomain: String!
    $startDate: String!
    $endDate: String!
    $systemName: String!
    $systemKey: String!
    $eventType: String!
  ) {
    getEventsBySystemName(
      domain: $ownerDomain
      startDate: $startDate
      endDate: $endDate
      systemName: $systemName
      systemKey: $systemKey
      eventType: $eventType
    )
  }
`;

export const GET_UNSCHEDULED_STOP_TIME = gql`
  query UnscheduledStopTime(
    $startDate: String!
    $endDate: String!
    $ownerDomain: String!
    $systemKey: String!
    $robotIp: String!
  ) {
    getUnscheduledDowntime(
      startDate: $startDate
      endDate: $endDate
      domain: $ownerDomain
      systemKey: $systemKey
      robotIp: $robotIp
    )
  }
`;

export const GET_UNSCHEDULED_STOP_TIME_FOR_SYSTEM = gql`
  query UnscheduledStopTimeForSystem(
    $startDate: String!
    $endDate: String!
    $ownerDomain: String!
    $systemKey: String!
    $systemName: String!
  ) {
    getUnscheduledDowntimeForSystem(
      startDate: $startDate
      endDate: $endDate
      domain: $ownerDomain
      systemKey: $systemKey
      systemName: $systemName
    )
  }
`;

export const GET_START_AND_STOP_EVENTS_BY_DATE_RANGE = gql`
  query($startDate: String!, $endDate: String!) {
    startAndStopEventsByDateRange(startDate: $startDate, endDate: $endDate)
  }
`;

export const GET_START_AND_STOP_EVENTS_BY_DATE_RANGE_BY_ROBOT = gql`
  query(
    $startDate: String!
    $endDate: String!
    $systemKey: String!
    $robotIp: String!
  ) {
    startAndStopEventsByDateRangeByRobot(
      startDate: $startDate
      endDate: $endDate
      systemKey: $systemKey
      robotIp: $robotIp
    )
  }
`;

export const GET_N_MOST_RECENT = gql`
  query($eventType: String!, $numberOfEvents: Int!) {
    nMostRecent(eventType: $eventType, numberOfEvents: $numberOfEvents) {
      data
      eventType
      time
      robotAddress
      systemKey
    }
  }
`;

export const GET_ALARMS_BY_CUSTOMER = gql`
  query($startDate: String!, $endDate: String!, $ownerDomain: String!) {
    getAlarmsByCustomer(
      ownerDomain: $ownerDomain
      startDate: $startDate
      endDate: $endDate
    ) {
      data
      time
      robotAddress
      systemKey
    }
  }
`;

export const GET_START_AND_STOP_EVENTS_BY_DATE_RANGE_FOR_ROBOT = gql`
  query(
    $startDate: String!
    $endDate: String!
    $ownerDomain: String!
    $systemKey: String!
    $robotIp: String!
  ) {
    startAndStopEventsByDateRange(
      startDate: $startDate
      endDate: $endDate
      domain: $domain
      systemKey: $systemKey
      robotIp: $robotIp
    )
  }
`;
