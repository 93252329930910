import React from "react";

export default function LoadingCell(props) {
  const message = props.message;
  return (
    <>
      {message != null && (
        <div className="d-flex justify-content-center align-vertical mt-4">
          <div className="col-12 text-center">
            <h1>{message}...</h1>
          </div>
        </div>
      )}
      <div className="d-flex justify-content-center align-vertical mt-4">
        <div className="spinner-border text-muted-green" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </>
  );
}
