import React from "react";
import "../styles/Parts.css";
import DetailView from "../components/DetailView";
import CellHeader from "../components/CellHeader";
import PartsGraph from "../graphs/PartsGraph.js";

const Parts = () => {
  return (
    <DetailView header="Parts">
      <div className="row">
        <div className="col-12 db-cell">
          <CellHeader title="Parts Data" />
          <div className="row">
            <div className="col-12 model-widget-holder">
              <PartsGraph />
            </div>
          </div>
        </div>
      </div>
    </DetailView>
  );
};

export default Parts;
