import React from "react";
import InfoPopover from "../components/Popovers";
import { NavLink } from "react-router-dom";

export default function CellHeader({
  title,
  subtitle,
  popoverTitle,
  popoverText,
  buttonTo,
  buttonText,
  popoverPlacement = "top",
  buttonData
}) {
  return (
    <>
      <div className="col-12">
        <div className="row">
          <h1>
            {title}{" "}
            {buttonTo != null && (
              <NavLink
                to={{
                  pathname: buttonTo,
                  passedProps: buttonData
                }}
              >
                <button type="button" className="btn btn-secondary ml-3">
                  {buttonText}
                </button>
              </NavLink>
            )}
          </h1>
          {popoverText != null && (
            <InfoPopover
              popoverTitle={popoverTitle}
              popoverText={popoverText}
              popoverPlacement={popoverPlacement}
            />
          )}
        </div>
      </div>
      {subtitle != null && (
        <div className="row">
          <div className="col-12">
            <h2 className="mb-1 mt-2">{subtitle}</h2>
          </div>
        </div>
      )}
    </>
  );
}
