import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

export default function ErrorCell(props) {
  if (props.error) console.error(props.error);
  return (
    <div className="col-12 mt-3 error-cell">
      <h5 className="text-danger mb-1 mt-1">
        <FontAwesomeIcon
          icon={faExclamationCircle}
          className="exclamation-circle-svg"
        />{" "}
        &nbsp;There was an error.
      </h5>
      <p>
        There was an error loading your data. We have been notified and this
        error should be resolved shortly. Thanks for your patience.
      </p>
    </div>
  );
}
