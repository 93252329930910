import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import { ApolloProvider } from "react-apollo-hooks";
import { AddSystemContextProvider } from "./context";
import Alarms from "components/alarms/Alarms";
import Dashboard from "components/dashboard/Dashboard";
import Navbar from "./Navbar";
import Parts from "./Parts";
import { initApolloClient } from "../auth";
import StopTime from "./StopTime";
import { initUserInfo } from "../components/User";
import { Login, getCachedSession } from "../components/Login";

function SignedInRoutes(props) {
  const user = props.user;
  const handleSignOut = props.handleSignOut;

  return (
    <>
      <main className="section container-fluid">
        <Navbar user={user} handleSignOut={handleSignOut} />
        <Switch>
          <Route
            exact
            path="/alarms"
            render={props => <Alarms {...props} user={user} />}
          />
          <Route
            exact
            path="/"
            render={props => <Dashboard {...props} user={user} />}
          />
          <Route
            exact
            path="/dashboard"
            render={props => <Dashboard {...props} user={user} />}
          />
          <Route exact path="/sign-in">
            {<Redirect to="/dashboard" />}
          </Route>
          <Route
            exact
            path="/parts"
            render={props => <Parts {...props} user={user} />}
          />
          <Route
            exact
            path="/start-stop-events"
            render={props => (
              <StopTime {...props} user={user} widget={"startStopTime"} />
            )}
          />
        </Switch>
      </main>
      <Footer />
    </>
  );
}

function Footer() {
  return (
    <footer className="footer mt-3">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            <p className="text-center"> &copy;2020 The House Of Design </p>
          </div>
          {window.displayVersion ? (
            <DisplayVersion versionData={window.version} />
          ) : null}
        </div>
      </div>
    </footer>
  );
}

function DisplayVersion(props) {
  const versionData = props.versionData;
  const versionHash = {};
  versionData
    .split("; ")
    .map(v => (versionHash[v.split("=")[0]] = v.split("=")[1]));
  return (
    <div className="col-xs-12 col-md-6 text-center text-monospace small-font">
      <p>
        <span className="badge badge-success badge-pill badge-light mr-2">
          Version
        </span>
        {versionData === "local" ? "local" : versionHash["branch"]}
      </p>
      {versionHash["date"] != null && (
        <p className="text-muted">Release date: {versionHash["date"]}</p>
      )}
    </div>
  );
}
export default class App extends Component {
  constructor(props) {
    super(props);
    this.handleSignInSuccess = this.handleSignInSuccess.bind(this);
    this.handleSignOut = this.handleSignOut.bind(this);
    this.updateSignInState = this.updateSignInState.bind(this);
    const { cognitoUser, cachedSession } = getCachedSession();
    if (cachedSession != null && cachedSession.isValid()) {
      // cached session is valid
      const oauthToken = cachedSession.getAccessToken().getJwtToken();
      const apolloClient = initApolloClient(oauthToken);
      const user = initUserInfo(cachedSession);
      this.state = {
        signedIn: true,
        auth: cognitoUser,
        user: user,
        session: cachedSession,
        apolloClient: apolloClient
      };
    } else {
      this.state = {
        signedIn: false,
        auth: [],
        user: [],
        session: [],
        apolloClient: []
      };
    }
  }
  handleSignInSuccess(auth, session) {
    const oauthToken = session.getAccessToken().getJwtToken();
    const apolloClient = initApolloClient(oauthToken);
    const user = initUserInfo(session);
    this.updateSignInState(auth, user, session, apolloClient);
  }
  updateSignInState(auth, user, session, apolloClient) {
    this.setState({
      auth: auth,
      signedIn: true,
      session: session,
      apolloClient: apolloClient,
      user: user
    });
  }
  handleSignOut() {
    const auth = this.state.auth;
    const apolloClient = this.state.apolloClient;
    try {
      auth.signOut();
    } catch (e) {
      console.error("Auth sign out error: " + e);
    }
    try {
      apolloClient.resetStore();
    } catch (e) {
      console.error("Apollo client reset error: " + e);
    }
    this.setState({
      auth: [],
      signedIn: false,
      session: [],
      apolloClient: [],
      user: []
    });
  }

  render() {
    const curUrl = window.location.href;
    const authorizationUrl = window.location.origin + "/sign-in";
    if (!this.state.signedIn) {
      if (curUrl !== authorizationUrl) {
        // not signed in- redirecting to auth url
        window.location.replace(authorizationUrl);
        return <Redirecting />;
      } else {
        // on authorization page; authorizing
        return <Login signInHandler={this.handleSignInSuccess} />;
      }
    }
    //signed in, load the app.
    return (
      <ApolloProvider client={this.state.apolloClient}>
        <AddSystemContextProvider>
          <Router>
            <SignedInRoutes
              user={this.state.user}
              handleSignOut={this.handleSignOut}
            />
          </Router>
        </AddSystemContextProvider>
      </ApolloProvider>
    );
  }
}
function Redirecting() {
  return (
    <div className="container">
      <div className="col-12">
        <h3 className="text-center mt-5">Redirecting...</h3>
        <div className="d-flex justify-content-center align-vertical mt-4">
          <div className="spinner-border text-muted-green" role="status">
            <span className="sr-only">Redirecting...</span>
          </div>
        </div>
      </div>
    </div>
  );
}
