import styles from "../styles/Navbar.module.css";
import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import hodNav from "../img/hod-nav.png";
import tecomet from "../img/tecomet.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import * as icons from "@fortawesome/free-solid-svg-icons";
import { slide as Menu } from "react-burger-menu";
import classNames from "classnames";
import "../styles/Navbar.css";

export default function Navbar(props) {
  const user = props.user;
  const handleSignOut = props.handleSignOut;
  const [sidebarIsOpen, setSidebarIsOpen] = useState(false);
  const close = () => setSidebarIsOpen(false);

  return (
    <>
      <nav
        className={classNames("navbar", styles.navbar)}
        role="navigation"
        aria-label="main navigation"
      >
        <button
          className={styles.burger}
          aria-label="menu"
          aria-expanded="false"
          onClick={() => setSidebarIsOpen(!sidebarIsOpen)}
        >
          <FontAwesomeIcon
            icon={sidebarIsOpen ? icons.faTimes : icons.faBars}
            size="2x"
            color="gray"
          />
        </button>
        <div className="navbar-brand d-none d-md-block ml-2">
          <Link to="/" className="navbar-item">
            <img
              src={hodNav}
              alt="House of Design Logo"
              className="integrator-logo"
            />
          </Link>
        </div>

        <div className="navbar-end mr-3">
          <img src={tecomet} alt="Tecomet logo" />
        </div>
      </nav>
      <Menu
        disableAutoFocus
        styles={{
          bmMenu: {
            overflow: "hidden"
          }
        }}
        className={styles.menu}
        customBurgerIcon={false}
        customCrossIcon={false}
        isOpen={sidebarIsOpen}
        onStateChange={state => setSidebarIsOpen(state.isOpen)}
      >
        <div className={styles.section}>
          <Link
            className={classNames(styles.sectionHeader, "navbar-menu-item")}
            to="/"
            onClick={close}
          >
            <FontAwesomeIcon icon={icons.faTachometerAlt} color="gray" />
            Dashboard
          </Link>
          <NavLink
            className={classNames(styles.menuItem, "navbar-menu-item")}
            to="/alarms"
            onClick={close}
          >
            Alarms
          </NavLink>
          <NavLink
            className={classNames(styles.menuItem, "navbar-menu-item")}
            to="/parts"
            onClick={close}
          >
            Parts
          </NavLink>
          <NavLink
            className={classNames(styles.menuItem, "navbar-menu-item")}
            to="/start-stop-events"
            onClick={close}
          >
            Start & Stop Events
          </NavLink>
        </div>

        <LogOutLink handleSignOut={handleSignOut} />

        <div className="d-block d-md-none text-center mt-5">
          <Link to="/">
            <img
              src={hodNav}
              alt="House of Design Logo"
              className="integrator-logo ml-auto mr-auto"
            />
          </Link>
        </div>

        <div className="ml-auto mr-auto mt-auto text-center">
          <p className="text-light user-email">
            <FontAwesomeIcon
              className="mr-1"
              icon={icons.faUserCircle}
              color="gray"
            />
            {user.email}
          </p>
        </div>
      </Menu>
    </>
  );
}

function LogOutLink(props) {
  const handleSignOut = props.handleSignOut;
  return (
    <div className={classNames(styles.section, "navbar-menu-item")}>
      <NavLink
        className={classNames(
          styles.sectionHeader,
          "navbar-menu-item",
          "log-out-link"
        )}
        to="/"
        onClick={handleSignOut}
      >
        <FontAwesomeIcon
          className="log-in-spinner"
          icon={icons.faSignOutAlt}
          color="gray"
        />
        <span className="log-out-spinner" style={{ display: "none" }}>
          <FontAwesomeIcon
            icon={faSpinner}
            color="gray"
            className="spinner-svg"
            pulse
          />
        </span>
        <span className="log-out-text">Log out</span>
      </NavLink>
    </div>
  );
}
