import React from "react";
import { GET_ALARMS_BY_CUSTOMER } from "components/Queries";
import { useQuery } from "react-apollo-hooks";
import { DateTime } from "luxon";
import LoadingCell from "components/LoadingCell";
import "styles/Alarms.css";
import ErrorCell from "components/ErrorCell";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faCheckCircle
} from "@fortawesome/free-solid-svg-icons";

export default function Alarms(props) {
  const user = props.user;
  const ownerDomain = user.ownerDomain;
  const endDate = React.useMemo(() => DateTime.local(), []);
  const startDate = endDate.minus({ days: 14 });

  const { loading, error, data } = useQuery(GET_ALARMS_BY_CUSTOMER, {
    variables: {
      startDate,
      endDate,
      ownerDomain
    }
  });

  if (loading) {
    return <LoadingCell />;
  }
  if (error) {
    console.error(data.error);
    return <ErrorCell />;
  }

  const recentAlarms = data.getAlarmsByCustomer.sort(function(a, b) {
    return new Date(a.time - b.time);
  });

  recentAlarms.sort(function(a, b) {
    var keyA = new Date(a.time),
      keyB = new Date(b.time);
    if (keyA > keyB) return -1;
    if (keyA < keyB) return 1;
    return 0;
  });

  if (recentAlarms.length > 0) {
    const last_alarm_date = recentAlarms[0].time;
    return (
      <>
        <div className="table-responsive">
          <table className="table table-dark table-striped">
            <thead>
              <tr>
                <th scope="col">Alarm Description</th>
                <th scope="col">Date</th>
                <th scope="col">Time</th>
                <th scope="col">Robot</th>
              </tr>
            </thead>
            <tbody>
              {recentAlarms.map((alarm, index) => (
                <tr key={index}>
                  <td>
                    <FontAwesomeIcon
                      icon={faExclamationTriangle}
                      color="var(--orange)"
                      className="exclamation-triangle-svg mr-2"
                    />
                    {JSON.parse(alarm.data).alarm_name}
                  </td>
                  <td>{DateTime.fromISO(alarm.time).toFormat("t")}</td>
                  <td className="">
                    {DateTime.fromISO(alarm.time).toFormat("MM/dd/yyyy")}
                  </td>
                  <td>{alarm.robotAddress}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="col-12">
          <p className="text-light text-right">
            Last system alarm was at{" "}
            {DateTime.fromISO(last_alarm_date).toFormat("t")} on{" "}
            {DateTime.fromISO(last_alarm_date).toFormat("MM/dd/yyyy")}
          </p>
        </div>
      </>
    );
  } else {
    return (
      <div className="col-12">
        <div className="row">
          <div className="alarm-data border-top mt-2 pt-2">
            <h6>
              <FontAwesomeIcon
                icon={faCheckCircle}
                color="var(--green)"
                className="check-circle-svg mr-2"
              />
              There have been no alarms within the last 14 days.
            </h6>
          </div>
        </div>
      </div>
    );
  }
}
