import React from "react";
import gql from "graphql-tag";
import LoadingCell from "../components/LoadingCell";
import ErrorCell from "../components/ErrorCell";
import { useQuery } from "react-apollo-hooks";
import { DateTime } from "luxon";
import ProgressCell from "../components/ProgressCell";

// QUALITY = GOOD COUNT / TOTAL COUNT

export default function Quality(props) {
  const endDate = React.useMemo(() => DateTime.local(), []);
  const startDate = endDate.startOf("Day");
  const user = props.user;
  const ownerDomain = user.ownerDomain;

  const start = startDate.minus({ days: 1 });
  const end = startDate.minus({ minutes: 1 });

  const data = useQuery(
    gql`
      query($startDate: String!, $endDate: String!, $ownerDomain: String!) {
        getOeeMetrics(
          startDate: $startDate
          endDate: $endDate
          ownerDomain: $ownerDomain
        )
      }
    `,
    {
      variables: {
        startDate: startDate.toISO({ includeOffset: false }),
        endDate: endDate.toISO({ includeOffset: false }),
        ownerDomain: ownerDomain
      }
    }
  );

  const prevDay = useQuery(
    gql`
      query($startDate: String!, $endDate: String!, $ownerDomain: String!) {
        getOeeMetrics(
          startDate: $startDate
          endDate: $endDate
          ownerDomain: $ownerDomain
        )
      }
    `,
    {
      variables: {
        startDate: start.toISO({ includeOffset: false }),
        endDate: end.toISO({ includeOffset: false }),
        ownerDomain: ownerDomain
      }
    }
  );

  if (data == null || data.loading) {
    return <LoadingCell />;
  } else if (data.error) {
    console.error("Quality Data error:" + data.error);
    return <ErrorCell />;
  }

  if (prevDay == null || prevDay.loading) {
    return <LoadingCell />;
  } else if (prevDay.error) {
    console.error("Quality Prev Day error:" + prevDay.error);
    return <ErrorCell />;
  }

  const x = data.data.getOeeMetrics;
  var qualityPercent = JSON.parse(x).Quality;

  const y = prevDay.data.getOeeMetrics;
  var qualityPrevPercent = JSON.parse(y).Quality;

  const diff = ((qualityPercent - qualityPrevPercent) * 100).toFixed(2);

  return (
    <ProgressCell
      strokeColor="#525868"
      trailColor="#52586880"
      percentage={qualityPercent}
      nanValue={0}
      gridArea="quality"
      diff={diff}
    />
  );
}
